<template>
    <div>
        <div hidden  id='purchaseInvoice' class="col-md-12">
            <!--page1-->
            <div>
                <!--HEADER-->
                <div  >
                    <HeaderComponent></HeaderComponent>
                                </div>

                <div style="height:20mm;margin-top:1mm; border:2px solid #000000;">
                    <div class="row mt-3">
                        <div class="col-md-12 ml-2 mr-2" style="font-size:16px;">
                            <div class="row">
                                <div class="col-md-6" style="display:flex;">
                                    <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">From Date:</div>
                                    <div style="width:50%; text-align:center;color:black !important;font-weight:bold;">{{getDate(fromDate)}}</div>
                                    <div style="width:22%;font-weight:bolder; padding-right:20px;font-size:15px !important;color:black !important;" ></div>
                                </div>
                                <div class="col-md-6" style="display:flex;">
                                    <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">To Date.:</div>
                                    <div style="width:50%; text-align:center;font-weight:bold;color:black !important;">{{getDate(toDate)}}</div>
                                    <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important" >
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--INFORMATION-->
                <div >
                    <div class="row ">
                        <div class="col-md-12 mt-4 ">
                            <div v-for="product in printDetails" v-bind:key="product.id">
                                <h5>{{ product.productName }} </h5>
                           
                            <table class="table">
                               
                                <tr class="heading" style="font-size:14px;border-bottom:1px solid;padding-bottom:15px">
                                    <th style="width:2%;padding-top:3px !important; padding-bottom:3px !important;border-top:0px !important">#</th>
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important;"> Date</th>
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">Document Name</th>
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;" >Transaction Type</th>
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">Price</th>
                                    <!-- <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">Average Price</th> -->
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">Quantity</th>
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">Curren Quantity</th>
                                    <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;color:black !important;border-top:0px !important ;">Sale Price</th>
                                </tr>


                                <tr style="font-size:14px;font-weight:bold;" v-for="(item, index) in product.productInventoryReport" v-bind:key="item.id">
                                    <td class="text-center" style="padding-top:8px !important; padding-bottom:3px !important;border-top:0 !important; border-bottom:0 !important;color:black !important;">{{index+1}}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{item.date}}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{(item.documentNumber) }}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;" >{{getTransactionType(item.transactionType)}}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{item.price}}</td>
                                    <!-- <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{item.averagePrice}}</td> -->
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">
{{ item.transactionType == 'SaleReturn' ? '+' : item.transactionType == 'StockIn' ? '+' : item.transactionType == 'StockOut' ? '-' : item.transactionType == 'StockIn' ? '+' : item.transactionType == 'SaleInvoice' ? '-' : item.transactionType == 'PurchaseReturn' ? '-' : item.transactionType == 'PurchasePost' ? '+' : '-' }}
                                            {{ item.quantity }}                                    </td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{item.currentQuantity}}</td>
                                    <td class="text-center" style="border-top: 0 !important; border-bottom: 0 !important; padding-top: 3px !important; padding-bottom: 3px !important;color:black !important;">{{item.salePrice}}</td>
                                </tr> 
                                
                               
                               

                            </table>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

</template>

<script>
    import moment from "moment";


    const options = {
        name: '',
        specs: [
            'fullscreen=no',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 700,
        autoClose: true,
        windowTitle: window.document.title,

    }
    export default {
        props: ['printDetails', 'fromDate','toDate'],
       
        data: function () {
            return {
              
              
                isHeaderFooter: '',
                invoicePrint: '',
                arabic: '',
                english: '',
                list: [],
                render: 0,
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                }
            }
        },
      
        
     
        methods: {
            getTransactionType(transactionType) {
            if (transactionType == 'StockOut') return this.$t('TheProductInventoryReport.StockOut')
            if (transactionType == 'JournalVoucher') return 'Journal Voucher'
            if (transactionType == 'ExpenseVoucher') return 'Expense Voucher'
            if (transactionType == 'Expense') return 'Expense'
            if (transactionType == 'BankPay') return 'Bank Pay'
            if (transactionType == 'BankReceipt') return 'Bank Receipt'
            if (transactionType == 'StockIn') return this.$t('TheProductInventoryReport.StockIn')
            if (transactionType == 'SaleInvoice') return this.$t('TheProductInventoryReport.SaleInvoice')
            if (transactionType == 'PurchaseReturn') return this.$t('TheProductInventoryReport.PurchaseReturn')
            if (transactionType == 'PurchasePost') return this.$t('TheProductInventoryReport.Purchase')
            if (transactionType == 'CashReceipt') return this.$t('TheProductInventoryReport.Cash')
            if (transactionType == 'SaleReturn') return 'Sale Return'
        },
       
            getDate: function (date) {
                return moment(date).format('l');
            },
            printInvoice: function () {

                this.$htmlToPaper('purchaseInvoice', options, () => {
                    

                   

                });
            },



        },
        mounted: function () {
            
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            var root = this;
            if (this.printDetails.length > 0) {
                this.list = this.printDetails;

                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }
        },

    }
</script>

